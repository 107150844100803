import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import Logo from "./Logo"
import Button from "./Button"

import { FiChevronDown } from "react-icons/fi"
import { Squash as Hamburger } from "hamburger-react"

const NavbarWrapper = styled.div`
  display: flex;
  justify-content: center;
  background: var(--base000);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;

  :after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: none;
    z-index: -1;
    box-shadow: 0px 10px 50px var(--base100);
    opacity: 0.05;
  }
`

const NavbarContainer = styled.div`
  padding: 16px 20px;
  display: flex;
  justify-content: space-between;
  position: relative;
  background: var(--base000);
  height: 80px;
  align-items: center;
  max-width: 1288px;
  width: 100%;
`

const SubNavWrapper = styled.div`
  position: relative;

  :hover > div:nth-of-type(2) {
    visibility: visible;
    opacity: 1;
  }

  @media screen and (max-width: 800px) {
    svg {
      display: none;
    }

    > *:first-child {
      display: none;
    }
  }
`

const SubNav = styled.div`
  display: grid;
  visibility: hidden;
  opacity: 0;
  grid-auto-flow: row;
  gap: 0;
  padding: 8px;
  border: 1px solid var(--base050);
  box-shadow: 0px 0px 20px var(--base010);
  border-radius: 2px;
  background: var(--base000);
  position: absolute;
  box-sizing: border-box;
  top: 100%;
  left: -8px;
  white-space: nowrap;
  transition: visibility 0s, opacity 150ms ease-in-out;

  @media screen and (max-width: 800px) {
    position: relative;
    visibility: inherit;
    opacity: 1;
    border: none;
    box-shadow: none;
    top: unset;
    left: unset;
    padding: 0;
  }
`

const NavRight = styled.div`
  display: grid;
  grid-auto-flow: column;
  gap: 8px;
  transition: visibility 0s, opacity 150ms ease-in-out;

  @media screen and (max-width: 800px) {
    height: auto;
    background: var(--base000);
    grid-auto-flow: row;
    position: absolute;
    padding: 0 16px 16px 16px;
    align-items: center;
    left: 0;
    right: 0;
    top: 100%;
    visibility: ${props => (props.state ? "visible" : "hidden")};
    opacity: ${props => (props.state ? 1 : 0)};
    overflow: hidden;
    z-index: -100;
    justify-items: stretch;
    gap: 0;
    box-shadow: 0px 20px 20px var(--base010);
    border-bottom: 1px solid var(--base050);

    > *:last-child {
      text-align: center;
    }
  }
`

const HamburgerWrapper = styled.div`
  display: none;
  @media screen and (max-width: 800px) {
    display: inline;
  }
`
class Navbar extends React.Component {
  state = {
    isActive: false,
  }

  handleClick = () => {
    this.setState(state => ({ isActive: !state.isActive }))
  }
  render() {
    return (
      <NavbarWrapper>
        <NavbarContainer>
          <Link to="/" aria-label="CueBack Home">
            <Logo height="37" color="var(--primary100)" />
          </Link>
          <NavRight state={this.state.isActive}>
            <SubNavWrapper>
              <Button>
                How We Help <FiChevronDown />
              </Button>
              <SubNav>
                <Button url="/development">Development</Button>
                <Button url="/alumni-relations">Alumni Relations</Button>
                <Button url="/artificial-intelligence">
                  AI With a Human Touch
                </Button>
              </SubNav>
            </SubNavWrapper>
            <Button url="/about">About</Button>
            <Button url="/blog">Blog</Button>
            <Button primary url="/demo">
              Request Demo
            </Button>
          </NavRight>
          <HamburgerWrapper>
            <Hamburger
              color={"var(--base100)"}
              size={24}
              easing="ease-in-out"
              duration={0.15}
              onToggle={this.handleClick}
              label="Toggle menu"
            />
          </HamburgerWrapper>
        </NavbarContainer>
      </NavbarWrapper>
    )
  }
}

export default Navbar
