import styled from "styled-components"

const Header1 = styled.h1`
  font-family: "Red Hat Display", Arial, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 53px;
  margin: 0;
  padding: 0;

  //Mobile
  /*
    font-size: 24px;
    line-height: 32px;
    */
`

const Header1Accent = styled.span`
  /*   font-family: "IBM Plex Mono", Courier, monospace;
 */
  font-size: 40px;
  line-height: 53px;
  font-weight: 700;
  margin: 0;
  padding: 0;
  color: var(--primary100);
  /* 
  :before {
    content: "{";
  }
  :after {
    content: "}";
  } */
`

const Header2 = styled.h2`
  font-family: "Red Hat Display", Arial, sans-serif;
  font-weight: 500;
  font-size: 32px;
  line-height: 42px;
  margin: 0;
  padding: 0;
`

const Header3 = styled.h3`
  font-family: "Red Hat Display", Arial, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 37px;

  letter-spacing: 0.01em;
  font-feature-settings: "case" on, "kern" off;
  margin: 0;
  padding: 0;
`

const Header4 = styled.h4`
  font-family: "Red Hat Display", Arial, sans-serif;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  font-feature-settings: "case" on, "kern" off;
  margin: 0;
  padding: 0;
`

const Header5 = styled.h5`
  font-family: "Red Hat Display", Arial, sans-serif;
  font-weight: 500;
  font-size: 21px;
  line-height: 32px;
  text-transform: uppercase;
  margin: 0;
  padding: 0;
`

const Header6 = styled.h5`
  font-family: "Red Hat Display", Arial, sans-serif;
  font-weight: 700;
  font-size: 21px;
  line-height: 32px;
  margin: 0;
  padding: 0;
`

const Body = styled.p`
  font-family: "Red Hat Text", Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 21px;
  line-height: 32px;
  margin: 0;
  padding: 0;
`

const Bold = styled.strong`
  font-family: "Red Hat Text", Arial, sans-serif;
  font-weight: 500;
`

const Label = styled.span`
  font-family: "Red Hat Text", Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 19px;
  line-height: 28px;
`

const Caption = styled.span`
  font-family: "Red Hat Display", Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 23px;
  margin: 0;
  padding: 0;
`

export {
  Header1,
  Header1Accent,
  Header2,
  Header3,
  Header4,
  Header5,
  Header6,
  Body,
  Bold,
  Label,
  Caption,
}
