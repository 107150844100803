import * as React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

const StyledButton = styled.div`
  /* Desktop */
  font-size: 16px;
  line-height: 100%;
  padding: 13px 20px;
  text-transform: uppercase;
  background: ${props => (props.primary ? "var(--base100)" : "none")};
  color: ${props => (props.primary ? "var(--base000)" : "var(--base070)")};
  border: ${props =>
    props.secondary ? "1px solid var(--base070)" : "1px solid transparent"};
  border-radius: 2px;
  font-family: "Red Hat Display", Arial, sans-serif;
  font-weight: 500;
  transition: box-shadow 150ms ease-in-out;
  cursor: pointer;
  position: relative;
  display: inline-block;

  :after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: none;
    opacity: 0.1;
  }

  :hover {
    color: ${props => (!props.primary ? "var(--base100)" : "var(--base000)")};
    border: ${props =>
      props.secondary ? "1px solid var(--base100)" : "1px solid transparent"};

    :after {
      box-shadow: ${props =>
        props.primary ? "0px 0px 40px var(--base100)" : "none"};
    }
  }
`

const StyledLink = styled(Link)`
  text-decoration: none;
  justify-self: start;
`
const StyledA = styled.a`
  text-decoration: none;
  justify-self: start;
`

function Button(props) {
  if (props.url) {
    var path = props.url
    var internal = path.charAt(0) === "/" ? true : false
  } else {
    var none = true
  }

  if (internal) {
    return (
      <StyledLink to={path}>
        <StyledButton internal {...props}>
          {props.children}
        </StyledButton>
      </StyledLink>
    )
  } else if (none) {
    return <StyledButton {...props}>{props.children}</StyledButton>
  } else {
    return (
      <StyledA href={path} aria-label={"Navigate to " + { path }}>
        <StyledButton {...props}>{props.children}</StyledButton>
      </StyledA>
    )
  }
}

export default Button
