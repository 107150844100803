/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { IconContext } from "react-icons"

import { GlobalStyle } from "../theme/theme"

import "./layout.css"
import Navbar from "./navbar"
import Section from "./Section"

import Seo from "./seo"

import Footer from "./Footer"

import { Header3, Body } from "./Typography"

import Button from "./Button"

const LayoutContainer = styled.div`
  width: 100vw;
`

const Main = styled.main`
  padding-top: 80px;
`

const Layout = ({ children, pageTitle, pageDescription, image, ...props }) => {
  return (
    <>
      <Seo title={pageTitle} description={pageDescription} image={image} />
      <GlobalStyle />
      <IconContext.Provider
        value={{ style: { verticalAlign: "middle", size: "1em" } }}
      >
        <LayoutContainer>
          <Navbar />
          <Main>
            {children}
            {props.noPunch ? (
              ""
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  background: "var(--primary005)",
                }}
              >
                <Section>
                  <div
                    style={{
                      display: "grid",
                      gridAutoFlow: "row",
                      justifyItems: "start",
                      gap: 16,
                    }}
                  >
                    <Header3>Stay ahead of the curve with CueBack</Header3>
                    <Body>
                      The world of advancement is changing. Don’t get left
                      behind. Stay ahead of the curve with insights from forward
                      thinkers in the industry who are paving the way for
                      tomorrow’s advancement professional leaders.
                    </Body>
                    <Button primary url="/demo" style={{ marginTop: 32 }}>
                      Request Demo
                    </Button>
                  </div>
                </Section>
              </div>
            )}

            <Section style={{ paddingBottom: 32 }}>
              <Footer />
            </Section>
          </Main>
        </LayoutContainer>
      </IconContext.Provider>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
